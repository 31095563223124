<template>
  <div class="login-wrapper">
    <b-card class="login-card">
      <div
        class="w-100 flex flex-column justify-content-center align-items-center"
        style="max-width: 500px"
      >
        <img class="logo mb-1" :src="company.logo" />
        <h1 class="h3 mb-4 font-weight-normal">Password Recovery</h1>
        <div v-if="step === 1" class="w-100 py-4">
          <b-form @submit.prevent="handleRequestRestore">
            <b-form-group
              label="Email"
              label-for="email"
              description="Enter your email to request a password reset link."
            >
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              :disabled="loading.requestRestore"
            >
              Request Password Reset
            </b-button>
            <b-spinner
              v-if="loading.requestRestore"
              small
              type="grow"
              variant="light"
              class="ml-2"
            ></b-spinner>
          </b-form>
        </div>

        <div v-if="step === 3" class="w-100 py-4">
          <p>Email has been sent</p>
        </div>

        <div v-if="step === 2" class="w-100 py-4">
          <b-form @submit.prevent="handlePerformRestore">
            <b-form-group
              label="New Password"
              label-for="new-password"
              description="Enter your new password."
            >
              <b-input-group>
                <b-form-input
                  id="new-password"
                  v-model="newPassword"
                  :type="showNewPassword ? 'text' : 'password'"
                  required
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    @click="togglePasswordVisibility('new')"
                    variant="outline-secondary"
                  >
                    <b-icon
                      :icon="showNewPassword ? 'eye-slash' : 'eye'"
                    ></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Confirm New Password"
              label-for="confirm-new-password"
              description="Re-enter your new password to confirm."
            >
              <b-input-group>
                <b-form-input
                  id="confirm-new-password"
                  v-model="confirmNewPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  required
                  :state="passwordMatch"
                  :feedback="passwordMatch ? '' : 'Passwords do not match'"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    @click="togglePasswordVisibility('confirm')"
                    variant="outline-secondary"
                  >
                    <b-icon
                      :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                    ></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              :disabled="!canSubmit || loading.performRestore"
            >
              Change Password
            </b-button>
            <b-spinner
              v-if="loading.performRestore"
              small
              type="grow"
              variant="light"
              class="ml-2"
            ></b-spinner>
          </b-form>
        </div>
      </div>
    </b-card>

    <img class="background-image" :src="backgroundImage" alt="" />
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";
import { mapGetters } from "vuex";
export default {
  name: "PasswordRecovery",
  data() {
    return {
      step: 1,
      loading: {
        requestRestore: false,
        performRestore: false,
      },
      email: "",
      token: this.$route.params.token || "",
      newPassword: "",
      confirmNewPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
      boUserIds: [],
    };
  },
  computed: {
    ...mapGetters(["company"]),

    backgroundImage() {
      return this.$store.state.config.config.url_background;
    },

    isBoUser() {
      return this.boUserIds.includes(this.currentUser?.id);
    },
    passwordMatch() {
      return this.newPassword === this.confirmNewPassword;
    },
    canSubmit() {
      return this.newPassword && this.confirmNewPassword && this.passwordMatch;
    },
  },
  methods: {
    async handleRequestRestore() {
      this.loading.requestRestore = true;
      const service = new AxiosService("UserBo");
      const domain = window.location.hostname;

      try {
        let res = await service.putCustomEndpoint(
          `UserBo/RequestRestore/${domain}/${this.email}`
        );
        if (res) {
          this.$bvToast.toast("Password reset link sent to your email", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.step = 3;
        } else {
          this.$bvToast.toast("Error requesting password reset", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } catch (error) {
        console.error("Error requesting password reset:", error);
        this.$bvToast.toast("Error requesting password reset", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.loading.requestRestore = false;
      }
    },
    async handlePerformRestore() {
      if (!this.canSubmit) {
        return;
      }

      this.loading.performRestore = true;
      const service = new AxiosService("UserBo");

      try {
        await service.putCustomEndpoint(
          "UserBo" + `/PerformRestore/${this.token}/${this.newPassword}`
        );
        this.$bvToast.toast("Password changed successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.newPassword = "";
        this.confirmNewPassword = "";
      } catch (error) {
        console.error("Error performing password reset:", error);
        this.$bvToast.toast("Error performing password reset", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.loading.performRestore = false;
        this.$router.push("/login");
      }
    },
    togglePasswordVisibility(field) {
      if (field === "new") {
        this.showNewPassword = !this.showNewPassword;
      } else if (field === "confirm") {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
  },
  mounted() {
    if (this.token != "email-request") {
      this.step = 2;
    } else {
      this.step = 1;
    }
  },
};
</script>

<style scoped>
/* General Styles */
.login-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-card {
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  padding: 40px 20px 10px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

.logo {
  max-width: 150px;
  height: auto;
}

.input-field {
  border-radius: 25px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.custom-button {
  border-radius: 25px;
  background-color: #2575fc;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #1a5bbf;
}

.forgot-password {
  font-size: 0.9rem;
  color: #666;
}

.reset-link {
  font-size: 0.9rem;
  color: #2575fc;
  text-decoration: none;
}

.reset-link:hover {
  color: #1a5bbf;
  text-decoration: underline;
}

.powered-by {
  color: #7a7a7a;
  text-align: center;
}

.powered-link img {
  width: 80px;
  height: auto;
  margin-left: 5px;
}

@media screen and (max-width: 500px) {
  .login-card {
    width: 90%;
    padding: 20px;
  }

  .logo {
    max-width: 120px;
  }

  .login-wrapper {
    padding: 10px;
  }
}
</style>
